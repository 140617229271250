import React from 'react'
import Myjobslist from './Myjobslist'


export default function Myjobs() {
  return (
    <>
    <div className='container-main'>
      <Myjobslist/>
    </div>
    </>
  )
}
