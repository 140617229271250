import React from 'react'

export default function Jobstatus() {
  return (
    <div className='container'>
   <div class="job-status-wrapper">
  <h2>Job Status</h2>
  <div class="status-grid">
    <div class="status-box">
      <p class="status-number">5</p>
      <p class="status-text">Available</p>
    </div>
    <div class="status-box">
      <p class="status-number">10</p>
      <p class="status-text">Picked</p>
    </div>
    <div class="status-box">
      <p class="status-number">10</p>
      <p class="status-text">Submitted</p>
    </div>
    <div class="status-box">
      <p class="status-number">3</p>
      <p class="status-text">Rejected</p>
    </div>
    <div class="status-box">
      <p class="status-number">1</p>
      <p class="status-text">QA Pending</p>
    </div>
    <div class="status-box">
      <p class="status-number">10</p>
      <p class="status-text">Completed</p>
    </div>
  </div>
</div>



    </div>
  )
}
