import React from 'react'
import CandidateList from './Candidateslist'

export default function Candidates() {
  return (
    <>
    <div className='container-main'>
     <CandidateList/>
    </div>
    </>
  )
}
